import React, {useContext, useEffect, useMemo, useState} from 'react';
import * as Ant from 'antd';
import UnAuthResult from '../../Components/UnAuthResult';
import {Context} from '../../AppContext';
import {errorHandler} from '../../errors';
import {navigate} from 'gatsby';
import ContentWrapper from '../../Components/Layout/ContentWrapper';
import {OrderBlock, Row, Text, Link, Button} from '../../Widgets';
const appConfig = require('../../data.json');
const qs = require('query-string');

export default function PeriodPage(props) {
  const app = useContext(Context);
  const {profile} = app.state;
  const {payment_status, period_id} = qs.parse(props.location.search);
  const [period, setPeriod] = useState();
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    async function getOrder() {
      let resp = await app.actions.getOrders({
        period_order: period_id,
        no_page: true,
      });
      setOrders(resp);
      return resp;
    }
    if (payment_status === 'success' && period_id && profile) {
      let timer = null;
      let count = 0;
      timer = setInterval(async () => {
        count++;
        let result = await getOrder();
        if (result?.length > 0 || count > 3) {
          clearInterval(timer);
        }
      }, 2000);
      return () => {
        clearInterval(timer);
      };
    }
  }, [app.actions, payment_status, period_id, profile]);

  useEffect(() => {
    if (period_id && profile) {
      (async () => {
        app.actions.setLoading(true);

        try {
          let resp = await app.actions.getPeriod(period_id);
          setPeriod(resp);
        } catch (err) {
          errorHandler(err, '取得委託單錯誤');
        }
        app.actions.setLoading(false);
      })();
    }
  }, [app.actions, payment_status, period_id, profile]);

  const {Status, Message} = useMemo(() => {
    try {
      let obj = JSON.parse(period?.payment_transaction_detail);
      return {...obj};
    } catch (err) {
      return {};
    }
  }, [period]);

  if (!profile) {
    return <UnAuthResult />;
  }

  return (
    <ContentWrapper>
      <OrderBlock title="委託單資訊">
        <Row>
          <Text size="sm" style={{marginRight: 10}}>
            委託單成立狀態：
          </Text>
          <Text
            size="sm"
            color={
              Status === 'SUCCESS'
                ? appConfig.colors.success
                : appConfig.colors.error
            }>
            {period
              ? Status === 'SUCCESS'
                ? '成功'
                : '錯誤'
              : payment_status === 'success'
              ? '成功'
              : '錯誤'}
          </Text>
        </Row>
        {Status !== 'SUCCESS' && (
          <Row>
            <Text size="sm" style={{marginRight: 10}}>
              錯誤訊息：
            </Text>
            <Text size="sm" color={appConfig.colors.error}>
              {Message}
            </Text>
          </Row>
        )}
        <Row>
          <Text size="sm" style={{marginRight: 10}}>
            期數：
          </Text>
          <Text size="sm">
            {period?.times} / {period?.auth_times}
          </Text>
        </Row>
        <Row>
          <Text size="sm" style={{marginRight: 10}}>
            每期金額：
          </Text>
          <Text size="sm">{period?.period_amount}</Text>
        </Row>
        <Row>
          <Text size="sm" style={{marginRight: 10}}>
            付款方式：
          </Text>
          <Text size="sm">
            {period?.payment_method === 'CREDIT' ? '信用卡' : ''}
          </Text>
        </Row>
        <Row>
          <Text size="sm" style={{marginRight: 10}}>
            訂單詳細：
          </Text>
          {orders?.length > 0 && Status === 'SUCCESS' ? (
            <Link to={`/order?id=${orders[0].id}`}>{orders[0].display_id}</Link>
          ) : (
            <Text size="sm">無訂單資訊</Text>
          )}
        </Row>
        <Row>
          <Text size="sm" style={{marginRight: 10}}>
            若沒有訂單資訊，可能在處理中，可重新整理此頁，或前往訂閱訂單列表查看
          </Text>
          <Button onClick={() => navigate(`/periods`)}>訂閱訂單列表</Button>
        </Row>

        <Row>
          <Text size="sm" style={{marginRight: 10}}>
            若委託單成立失敗，請前往購物車再次下單
          </Text>
          <Ant.Button onClick={() => navigate(`/cart`)}>購物車</Ant.Button>
        </Row>
      </OrderBlock>
    </ContentWrapper>
  );
}
