import React, {useContext, useState, Fragment} from 'react';
import styled from 'styled-components';
import {Context} from '../AppContext';
import {Alert, message} from 'antd';
import {Input, Button, Divider, Text, Link, Row} from '../Widgets';
import Image from '../Components/Image';
import {ErrLogin, errorHandler} from '../errors';
const config = require('../data.json');

// function errorHandler(ex) {
//   console.warn(ex);

//   // if(ex && ex.error === "user_not_found") {
//   //   message.warning("查無此使用者");
//   // }
// }

const UISTATE = {
  normal: 'normal',
  enterprise: 'enterprise',
};

export default function Login(props) {
  const [uiState, setUiState] = useState(UISTATE.normal);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [gui_number, setGuiNumber] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const app = useContext(Context);

  const login = async () => {
    setLoading(true);

    try {
      if (uiState === UISTATE.enterprise && !gui_number) {
        throw new ErrLogin('統編必填');
      }
      if (!username) {
        throw new ErrLogin('信箱必填');
      }
      if (!password) {
        throw new ErrLogin('密碼必填');
      }

      await app.actions.login({username, password, gui_number});
      app.actions.getCart();
      app.actions.setModal(null);
    } catch (ex) {
      if (ex.error === 'user_type_error') {
        ex = new ErrLogin('此帳號非此會員類型，請選擇正確的類型');
      } else {
        setError(true);
      }

      errorHandler(ex, '登入失敗');
    }
    setLoading(false);
  };

  return (
    <Wrapper>
      <Image
        src="/images/logo.svg"
        alt="logo"
        style={{width: 150, margin: 'auto', marginBottom: 30}}
      />

      {error && (
        <Alert
          message="登入失敗"
          description={'請重新確認您的信箱與密碼，然後再試一次'}
          type="error"
          showIcon
          style={{marginBottom: 10}}
        />
      )}

      <div style={{display: 'flex', marginBottom: 20}}>
        <Button
          type={uiState === UISTATE.normal ? 'primary' : 'default'}
          onClick={() => {
            setGuiNumber('');
            setUiState(UISTATE.normal);
          }}
          style={{flex: 1}}>
          一般會員
        </Button>
        <Button
          type={uiState === UISTATE.enterprise ? 'primary' : 'default'}
          onClick={() => setUiState(UISTATE.enterprise)}
          style={{flex: 1}}>
          企業會員
        </Button>
      </div>

      {uiState === UISTATE.normal ? (
        <React.Fragment>
          <Row>
            <Input
              id="login-username-input"
              placeholder="信箱"
              value={username}
              style={{padding: 12, flex: 1, margin: 0}}
              onChange={(e) => {
                let value = e.target.value;
                setUsername(value);
                setError(null);
              }}
            />
          </Row>

          <Row>
            <Input
              id="login-password-input"
              placeholder="密碼"
              type="password"
              value={password}
              style={{padding: 12, flex: 1, margin: 0}}
              onChange={(e) => {
                let value = e.target.value;
                setPassword(value);
                setError(null);
              }}
            />
          </Row>

          <Button
            loading={loading}
            onClick={login}
            style={{alignSelf: 'stretch', marginBottom: 10}}>
            {' '}
            登入
          </Button>

          <Link
            to="/fgpass"
            onClick={() => app.actions.setModal(null)}
            style={{alignSelf: 'flex-end'}}>
            <Text size="xs" style={{opacity: 0.7}}>
              忘記密碼？
            </Text>
          </Link>

          <Divider>
            <Text size="sm">或</Text>
          </Divider>

          <Row style={{justifyContent: 'center'}}>
            <SocialSignInBtn css="background-color: #0183FF;">
              <Image
                alt="social signin - facebook"
                linkto={`${config.endpoint.apiHost}/auth/facebook`}
                src="/images/socialsign-facebook.png"
                style={{width: 28, height: 28}}
              />
            </SocialSignInBtn>

            <SocialSignInBtn>
              <Image
                alt="social signin - google"
                linkto={`${config.endpoint.apiHost}/auth/google`}
                src="/images/socialsign-google.png"
                style={{width: 28, height: 28}}
              />
            </SocialSignInBtn>
          </Row>

          <Divider />

          <Row style={{justifyContent: 'center'}}>
            <Text size="sm">還不是會員嗎？</Text>
            <Link
              to="/register"
              onClick={() => {
                app.actions.setModal(null);
                app.actions.setEnterpriseRegister(false);
              }}
              style={{color: config.colors.main}}>
              立刻註冊新帳號
            </Link>
          </Row>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Row>
            <Input
              id="login-gui-number-input"
              placeholder="統編"
              value={gui_number}
              style={{padding: 12, flex: 1, margin: 0}}
              onChange={(e) => {
                let value = e.target.value;
                setGuiNumber(value);
                setError(null);
              }}
            />
          </Row>

          <Row>
            <Input
              id="login-username-input"
              placeholder="信箱"
              value={username}
              style={{padding: 12, flex: 1, margin: 0}}
              onChange={(e) => {
                let value = e.target.value;
                setUsername(value);
                setError(null);
              }}
            />
          </Row>

          <Row>
            <Input
              id="login-password-input"
              placeholder="密碼"
              type="password"
              value={password}
              style={{padding: 12, flex: 1, margin: 0}}
              onChange={(e) => {
                let value = e.target.value;
                setPassword(value);
                setError(null);
              }}
            />
          </Row>

          <Button
            loading={loading}
            onClick={login}
            style={{alignSelf: 'stretch', marginBottom: 10}}>
            {' '}
            登入
          </Button>

          <Link
            to="/fgpass"
            onClick={() => app.actions.setModal(null)}
            style={{alignSelf: 'flex-end'}}>
            <Text size="xs" style={{opacity: 0.7}}>
              忘記密碼？
            </Text>
          </Link>

          <Divider />

          <Text size="sm">
            原Social登入之企業會員，請切換到
            <span
              style={{cursor: 'pointer', color: config.colors.link}}
              onClick={() => setUiState(UISTATE.normal)}>
              一般會員
            </span>
            下方的Google, Facebook登入。
          </Text>
          <Divider />

          <Row style={{justifyContent: 'center'}}>
            <Text size="sm">還不是會員嗎？</Text>
            <Link
              to="/register"
              onClick={() => {
                app.actions.setModal(null);
                app.actions.setEnterpriseRegister(true);
              }}
              style={{color: config.colors.main}}>
              立刻註冊新帳號
            </Link>
          </Row>
        </React.Fragment>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  align-items: stretch;
  flex-direction: column;
`;

const SocialSignInBtn = styled.div`
  display: block;
  width: 60px;
  height: 60px;
  border-radius: 30px;
  border: 1px solid ${config.colors.border};
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px 10px;

  & img {
    width: 28px;
    height: 28px;
  }

  ${(props) => props.css || ''}
`;

const metadata = {
  title: false,
  width: '400px',
};

export {metadata};
