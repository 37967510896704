import React, {useContext} from 'react';
import {Context} from '../AppContext';
import {Button, Text} from '../Widgets';
import Login, {metadata} from '../Modals/Login';
import ResultPage from './ResultPage';

export default function UnAuthResult(props) {
  const app = useContext(Context);
  const {autoLoginLoading} = app.state;

  if (autoLoginLoading) {
    return (
      <ResultPage image="/images/error-login.png">
        <Text weight="600" style={{marginBottom: 5}}>
          載入中
        </Text>
        <Text weight="600" style={{marginBottom: 5}}>
          嘗試登入會員資料...
        </Text>
      </ResultPage>
    );
  }

  return (
    <ResultPage image="/images/error-login.png">
      <Text weight="600" style={{marginBottom: 5}}>
        很抱歉，您尚未登入！
      </Text>
      <Text weight="600" style={{marginBottom: 5}}>
        請登入會員或前往註冊新帳號。
      </Text>
      <Button
        onClick={() => {
          app.actions.setModal({
            content: <Login />,
            ...metadata,
          });
        }}>
        登入
      </Button>
    </ResultPage>
  );
}
